import React from 'react';
import ShellRootContext from './ShellRootContext';
import { Stack } from '@jarvis/web-stratus-client';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const ShellRootProvider = (props) => {
  const flags = useFlags();

  const getFlags = () => {
    if (props.isDemo) {
      return {
        enableJobHistory: true,
        enablePartnerlink: true,
      };
    } else {
      return flags;
    }
  };

  return (
    <ShellRootContext.Provider
      value={{
        ecpDeviceV55: props.ecpDeviceV55,
        stack: props.stack ?? Stack.pie,
        shell: props.shell,
        events: props.events,
        localization: props.localization,
        useToast: props.useToast(),
        authProvider: props.authProvider,
        navigation: props.navigation,
        getBreadcrumb: props.getBreadcrumb,
        setBreadcrumb: props.setBreadcrumb,
        showConfiguration: props.showConfiguration,
        showOverview: props.showOverview,
        showFWUpdate: props.showFWUpdate,
        showPolicy: props.showPolicy,
        showJobs: props.showJobs,
        showLastUpdatedSection: props.showLastUpdatedSection,
        showDetailsTab: props.showDetailsTab,
        customRelativePath: props.customRelativePath,
        featureFlags: getFlags(),
        accessControl: props.accessControl,
        theme: props.theme,
        isWex: props.isWex,
        mode: props.mode,
        getConfig: props.getConfig,
        isFromApps: props.isFromApps,
        appUuid: props.appUuid,
      }}
    >
      {props.children}
    </ShellRootContext.Provider>
  );
};
