import styled from 'styled-components';
import React from 'react';
import { t, capitalizeFirstLetter, getStatus } from 'src/utils/commonMethods';
import { IconCheckmarkCircle, IconInfo, IconWarningAlt, IconXCircle, Tooltip } from '@veneer/core';

type TDeviceStatusProps = {
  status: {
    acceptingJobs: boolean;
    printerStateSeverity: string;
    printerState: string;
  };
};

const DeviceStatus = (props: TDeviceStatusProps) => {
  const status = getStatus(props.status);

  const getStatusText = () => {
    switch (status) {
      case 'ERROR':
        return t('info.error');
      case 'WARNING':
        return t('info.warning');
      case 'READY':
        return t('info.ready');
    }
  };

  const getStatusIcon = () => {
    switch (status) {
      case 'ERROR':
        return (
          <IconXCircle
            data-testid={'device-status-state-xcircle-icon'}
            color="red6"
            filled={true}
            size={24}
          />
        );
      case 'WARNING':
        return (
          <IconWarningAlt
            data-testid={'device-status-state-warning-icon'}
            color="darkOrange5"
            filled={true}
            size={24}
          />
        );
      case 'READY':
        return (
          <IconCheckmarkCircle
            data-testid={'device-status-state-check-circle-icon'}
            color="green6"
            filled={true}
            size={24}
          />
        );
    }
  };
  return (
    <Wrapper data-testid={'device-status'}>
      <Content>
        <Title data-testid={'status-text'}>
          <Text>{t('info.status')}</Text>
          <Tooltip content={t('message.tooltip')} placement="bottom">
            <IconInfo size={18} />
          </Tooltip>
        </Title>

        <StatusWrapper>
          {getStatusIcon()}
          <TextLabel>{capitalizeFirstLetter(getStatusText())}</TextLabel>
        </StatusWrapper>
      </Content>
    </Wrapper>
  );
};

export default DeviceStatus;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 10px 0;
  min-width: 90px;

  .status-icon {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
`;

const TextLabel = styled.label`
  margin-top: -2px;
`;

const Content = styled.div``;

const Title = styled.div`
  line-height: 16px;
  font-size: 14px;
  color: var(--gray500);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.span`
  margin-right: 4px;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3px;
  color: #333333;
  font-weight: bold;
  font-size: 16px;

  svg {
    margin-top: -2px;
    margin-right: 5px;
  }
`;
