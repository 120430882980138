import React, { useEffect, useState } from 'react';
import rootReducer from 'src/store';
import packageInfo from '../package.json';
import { Provider } from 'react-redux';
import { App } from '../src';
import { configureStore } from '@reduxjs/toolkit';
import { ShellRootProvider } from 'src/contexts/ShellRoot';
import { ToastProvider, DirectionProvider, useToast } from '@veneer/core';
import { CURRENT_STACK } from '../src/utils/constants';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { TShellProps } from '../src/types/TShellProps';
import CustomThemeProvider from '../src/contexts/CustomThemeProvider/CustomThemeProvider';

declare global {
  interface Window {
    Shell: TShellProps;
  }
}

declare let window: any;
declare let sessionStorage: any;

export default function Root(props) {
  const { v1 } = window.Shell as TShellProps;
  const namespace = '@jarvis/react-ecp-device-details';
  const directionValue = v1?.localization?.useReactGetLanguageDirection?.(React);
  const [LDProvider, setLDProvider] = useState(null);

  const getLDProvider = async () => {
    let clientID = '';

    if (CURRENT_STACK.LOCAL) clientID = '6232996cff5a0714a84243fc';
    else if (CURRENT_STACK.PIE) clientID = '6232996cff5a0714a84243ff';
    else if (CURRENT_STACK.STAGE) clientID = '6232996c7927321456ef8f7f';
    else {
      //production
      clientID = '6232996c7927321456ef8f82';
    }
    const Provider = await asyncWithLDProvider({
      clientSideID: clientID,
    });
    return Provider;
  };

  useEffect(() => {
    const setProvider = async () => {
      const Provider = await getLDProvider();

      setLDProvider(() => Provider);
    };
    setProvider();
  }, []);

  const getStore = () => {
    return configureStore({
      reducer: rootReducer,
      devTools: process.env.NODE_ENV !== 'production',
    });
  };

  if (props.isWex && props.AppBar != undefined) {
    const crumbs = [
      {
        text: 'Fleet Management',
        route: 'fleetManagementDashboard',
      },
      {
        text: 'Devices',
        route: 'devices',
        routeParams: { parentTab: 'Printer' },
        layout: 'fleetLayout',
      },
      { text: 'Devices Details' },
    ];
    props.AppBar.setPageDetails({
      title: 'Device',
      crumbs: crumbs,
      pageId: 'deviceDetails',
    });
  }

  return LDProvider ? (
    <section id={namespace}>
      <DirectionProvider direction={directionValue}>
        <ToastProvider>
          <LDProvider>
            <ShellRootProvider
              id={packageInfo.name}
              ecpDeviceV55={sessionStorage.getItem('ecpDeviceV55') ?? false}
              stack={props.stack}
              shell={props.shell}
              localization={v1.localization}
              useToast={useToast}
              events={v1.events}
              authProvider={v1.authProvider}
              navigation={v1.navigation}
              getBreadcrumb={v1.breadcrumbs.getBreadcrumbs}
              setBreadcrumb={v1.breadcrumbs.add}
              showConfiguration={props.showConfiguration ?? true}
              showOverview={props.showOverview ?? true}
              showFWUpdate={props.showFWUpdate ?? true}
              showPolicy={props.showPolicy ?? true}
              showJobs={props.showJobs ?? true}
              showLastUpdatedSection={props.showLastUpdatedSection ?? true}
              showDetailsTab={props.showDetailsTab ?? true}
              customRelativePath={props.customRelativePath ?? '/devices'}
              featureFlags={null}
              accessControl={v1.accessControl}
              theme={v1.theme}
              isWex={props.isWex ?? false}
              mode={props.configPath?.theme.mode ?? 'light'}
              getConfig={props.getConfig ?? undefined}
              isFromApps={props.isFromApps ?? false}
              appUuid={props.appUuid ?? ''}
            >
              <CustomThemeProvider>
                <Provider store={getStore()}>
                  <App {...props} />
                </Provider>
              </CustomThemeProvider>
            </ShellRootProvider>
          </LDProvider>
        </ToastProvider>
      </DirectionProvider>
    </section>
  ) : (
    <></>
  );
}
