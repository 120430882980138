import 'src/styles/global.scss';
import styled from 'styled-components';
import React, { useMemo } from 'react';
import { setProps, t } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { GlobalHeader } from 'src/components/atom';
import { useStoreState } from 'src/store/useStoreState';
import { useConstructor } from './hooks/useConstructor';
import { DetailsMainPage } from './components/page';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { getDetailsRnPmActions } from 'src/store/detailsRnPm/init';
import { getDetailsStoreActions } from 'src/store/details/init';

export const App = (props) => {
  const { detailsState } = useStoreState();
  const { startRootComponent, deviceId } = detailsState;
  const {
    isWex,
    accessControl,
    getBreadcrumb,
    setBreadcrumb,
    customRelativePath,
    showLastUpdatedSection,
  } = useShellRootContext();
  const dispatch = useDispatch();
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));

  // init all redux stores
  useConstructor(() => {
    setProps();

    getDetailsRnPmActions(accessControl, dispatch).finally(() => {
      dispatchAll(getDetailsStoreActions(props));
    });

    if (!isWex) {
      if (customRelativePath === '/devices' && getBreadcrumb().at(-1).text === 'Devices') {
        const newBread = {
          text: t('label.details'),
          url: `${customRelativePath}/${props.deviceId}`,
          key: `menu-devices-${props.deviceId}`,
        };
        setBreadcrumb(newBread);
      } else if (
        customRelativePath === '/unassigned-devices' &&
        getBreadcrumb().at(-1).text === 'Unassigned Devices'
      ) {
        const newBread = {
          text: t('label.details'),
          url: `${customRelativePath}/${props.deviceId}`,
          key: `menu-unassigned-devices-${props.deviceId}`,
        };
        setBreadcrumb(newBread);
      }
    }
  });

  const didReduxUpdated = () => {
    return startRootComponent && deviceId !== '';
  };

  const renderRootComponent = useMemo(() => {
    if (didReduxUpdated()) {
      return (
        <>
          {!isWex && showLastUpdatedSection && <GlobalHeader title={`< ${t('label.details')}`} />}
          <Wrapper id="device-details-main-content" data-testid="root-component">
            <DetailsMainPage {...props} />
          </Wrapper>
        </>
      );
    } else {
      return <></>;
    }
  }, [didReduxUpdated()]);

  return <>{renderRootComponent}</>;
};

const Wrapper = styled.div`
  padding: 24px;
`;
