import { refined, refinedDate } from 'src/utils/commonMethods';

export const refinedUseGetDeviceInfo = (data) => {
  const refinedSupplies = (data: any) => {
    if (data === undefined || data === null) return null;
    if (data && data.length === 0) return null;

    const refined = {
      ink: [],
      otherSupplies: [],
      printHead: [],
    };
    data.forEach((item) => {
      switch (item.supplyType) {
        case 'inkCartridge':
        case 'ink':
        case 'inkTank':
          refined.ink.push({
            type: 'inkCartridge',
            status: item.state ?? 'error',
            stateReason: item.stateReasons?.[0] ?? '--',
            percentLifeDisplay: item.percentLifeDisplay ?? -1,
            color: item.colors?.[0] ?? '-',
          });
          break;
        case 'printHead':
          refined.printHead.push({
            id: item.slot,
            type: 'printHead',
            status: item.state ?? 'error',
            stateReason: item.stateReasons?.[0] ?? '--',
            colors: item.colors ?? null,
          });
          break;
        case 'maintenanceCartridge':
          refined.otherSupplies.push({
            type: 'maintenanceCartridge',
            status: item.state ?? 'error',
            stateReason: item.stateReasons ?? [],
            percentLifeDisplay: item.percentLifeDisplay ?? -1,
          });
          break;
        case 'preventiveMaintenanceKit':
        default:
          return;
      }
    });
    return refined;
  };

  const refinedMedia = (data: any) => {
    if (data === undefined || data === null) return null;
    if (data && data.length === 0) return null;

    const refined = [];
    data.forEach((media) => {
      refined.push({
        type: media.type,
        id: media.mediaSourceId,
        state: media.state,
        stateReason: media.stateReason,
      });
    });
    return { media: refined };
  };

  const refinedSolutionMetadata = (data: any) => {
    const emptySolutionMetadata = {
      result: 'unknown',
    };
    if (data === null || data === undefined || Object.values(data).length === 0) {
      return [emptySolutionMetadata];
    }
    let objNullChecker = false;
    for (const key in data) {
      if (data[key] === null || data[key] === undefined) {
        objNullChecker = true;
      }
    }
    if (objNullChecker) {
      return [emptySolutionMetadata];
    }

    return Object.values(data)?.map(
      (obj: {
        state: string;
        result: string;
        policyId: string;
        lastAssessed: string;
        securityPolicy: string;
        securityPolicyCount: number;
      }) => ({
        state: refined(obj.state),
        result: refined(obj.result),
        policyId: refined(obj.policyId),
        lastAssessed: refinedDate(obj.lastAssessed),
        securityPolicy: refined(obj.securityPolicy),
        securityPolicyCount: obj.securityPolicyCount ?? 0,
      }),
    );
  };

  const refinedNetworkAdapter = (data: any) => {
    const emptyAdapter = {
      enabled: false,
      hostname: '--',
      ipv4: { address: { ip: '--' }, enabled: false },
      ipv6: { address: { ip: '--' }, enabled: false },
      macAddress: '--',
      type: '--',
    };
    if (data === undefined || data === null || !data.adapters) {
      return [
        {
          ...emptyAdapter,
          name: 'eth0',
        },
        {
          ...emptyAdapter,
          name: 'wifi0',
        },
      ];
    } else {
      const adapters = data.adapters;
      const networkAdapters = [];

      adapters.forEach((adapter) => {
        if (adapter.name.startsWith('eth') || adapter.name.startsWith('wifi')) {
          adapter.hostname = refined(adapter.hostname);
          adapter.macAddress = refined(adapter.macAddress?.toUpperCase());
          adapter.name = refined(adapter.name);
          adapter.ipv4.address.ip = refined(adapter.ipv4.address.ip);
          adapter.ipv6.address.ip = refined(adapter.ipv6.address.ip?.toUpperCase());
          networkAdapters.push(adapter);
        }
      });
      return networkAdapters;
    }
  };

  return {
    connTypes: data.connTypes ?? [],
    deviceId: refined(data.deviceId),
    groups:
      data.groups?.map((innerObj) => ({
        uuid: refined(innerObj.uuid),
        label: refined(innerObj.label),
        parent: refined(innerObj.parent),
      })) ?? [],
    lastUpdatedAt: refined(data.lastUpdatedAt),
    type: refined(data.type),
    identity: {
      lastUpdatedAt: refined(data.identity?.lastUpdatedAt),
      serialNumber: refined(data.identity?.serialNumber),
      deviceUuid: refined(data.identity?.deviceUuid),
      makeAndModel: {
        name: refined(data.identity?.makeAndModel?.name),
        number: refined(data.identity?.makeAndModel?.number),
      },
      location: refined(data.identity?.location),
      friendlyName: refined(data.identity?.friendlyName),
      assetNumber: refined(data.identity?.assetNumber),
      firmwareVersion: refined(data.identity?.firmwareVersion),
    },
    status: {
      lastUpdatedAt: refined(data.status?.lastUpdatedAt),
      connectionState: data.status?.connectionState ?? 'offline',
      acceptingJobs: data.status?.acceptingJobs ?? false,
      printerState: refined(data.status?.printerState),
      printerStateSeverity: refined(data.status?.printerStateSeverity),
    },
    supplies: {
      lastUpdatedAt: refined(data.status?.lastUpdatedAt),
      consumables: refinedSupplies(data.supplies?.consumables),
    },
    mediaTypes: {
      inputs: refinedMedia(data.mediaTypes?.inputs),
    },
    images:
      data.images?.map((obj) => ({
        url: refined(obj.url),
      })) ?? [],
    solutionMetadata: refinedSolutionMetadata(data.solutionMetadata),
    solutions: data?.solutions ?? [],
    ownership: {
      accountId: refined(data.ownership?.accountId),
      userId: refined(data.ownership?.userId),
      ownershipId: refined(data.ownership?.ownershipId),
      fqTenantId: refined(data.ownership?.fqTenantId),
      lastUpdatedAt: refined(data.ownership?.lastUpdatedAt),
    },
    timestamp: {
      firstRegistrationTime: refined(data.timestamp?.firstRegistrationTime),
    },
    network: { adapters: refinedNetworkAdapter(data.network) },
  };
};
