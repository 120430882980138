import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { isMockUpMode } from 'src/utils/commonMethods';

import getSubscriptionMock from 'src/mock/subscriptions/get-subscriptions.json';

export const subscriptionsAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.subscriptions);

  const getSubscriptions = async (productNumber, serialNumber, deviceId) => {
    let response, error;

    if (isMockUpMode()) {
      response = getSubscriptionMock;

      // await getMockUpData('subscriptions/get-subscriptions').then((data) => {
      //   response = data;
      // });
      return { response, error };
    }

    await client
      .list({
        productNumber: productNumber,
        serialNumber: serialNumber,
        deviceId: deviceId,
      })
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getSubscriptions,
  };
};
