import styled from 'styled-components';
import tokens from '@veneer/tokens';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import { Tabs } from '@veneer/core';
import { TabMfe } from 'src/components/atom';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { t, getBaseName, getPathname } from 'src/utils/commonMethods';
import { NavLink, BrowserRouter as Router, Route, Switch } from 'react-router-dom';

export const DeviceDetailsTab = (props) => {
  const baseName = getBaseName();
  const { detailsState, detailsRnPmState } = useStoreState();
  const { deviceId, isPartnerLinkSupported, isFromUnassigned } = detailsState;
  const { featuresConstraintsReadRnPm, policyReadRnPm, policyListRnPm, jobHistoryReadRnPm } =
    detailsRnPmState;
  const {
    isWex,
    stack,
    shell,
    showJobs,
    showPolicy,
    navigation,
    showOverview,
    featureFlags,
    showConfiguration,
    customRelativePath,
  } = useShellRootContext();
  const [currentTab, setCurrentTab] = useState<number | undefined>(getCurrentTab());

  useEffect(() => {
    function tabHandler() {
      setCurrentTab(getCurrentTab());
    }
    if (!isWex) window.addEventListener('popstate', tabHandler);

    return () => {
      if (!isWex) {
        console.log('cleanup');
        window.removeEventListener('popstate', tabHandler);
      }
    };
  }, []);

  function getCurrentTab() {
    const baseurl = customRelativePath || '/';
    const pathArray = navigation ? navigation.location.pathname.split('/') : baseurl.split('/');
    const tabPath = pathArray[pathArray.length - 1];

    switch (tabPath) {
      case 'fleetmanager':
        return 5;
      case `policy`:
        return 4;
      case `partnerlink`:
        return 3;
      case `configuration`:
        return 2;
      case 'jobs':
        return 1;
      default:
        return 0;
    }
  }

  const tabs = [];

  if (showOverview) {
    tabs.push({
      id: 0,
      label: (
        <StyledNavLink
          exact
          isWex={isWex}
          to={`${getPathname()}/${deviceId}`}
          activeClassName="selected"
          data-testid="overview-tab"
        >
          {t('label.overview')}
        </StyledNavLink>
      ),
      title: 'Device Details: Overview',
      description: '',
      content: isWex ? (
        <TabMfe
          {...props}
          type="ECPDevicesDetailsOverview"
          component="@jarvis/react-ecp-device-details-overview"
        />
      ) : (
        <Route path="">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsOverview"
            component="@jarvis/react-ecp-device-details-overview"
          />
        </Route>
      ),
    });
  }

  if (
    showJobs &&
    !isFromUnassigned &&
    jobHistoryReadRnPm &&
    featureFlags.enableJobHistory &&
    !isWex
  ) {
    tabs.push({
      id: 1,
      label: (
        <StyledNavLink
          isWex={isWex}
          to={`${getPathname()}/${deviceId}/jobs`}
          activeClassName="selected"
          data-testid="jobs-tab"
        >
          {t('label.jobs')}
        </StyledNavLink>
      ),
      title: 'Device Details: Jobs',
      description: '',
      content: (
        <Route path="(|/jobs)">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsJobs"
            component="@jarvis/react-ecp-device-details-job-history"
          />
        </Route>
      ),
    });
  }

  if (showConfiguration && featuresConstraintsReadRnPm && !isFromUnassigned) {
    tabs.push({
      id: 2,
      label: (
        <StyledNavLink
          isWex={isWex}
          to={`${getPathname()}/${deviceId}/configuration`}
          activeClassName="selected"
          data-testid="configuration-tab"
        >
          {t('label.properties')}
        </StyledNavLink>
      ),
      title: 'Device Details: Configuration',
      description: '',
      content: isWex ? (
        <TabMfe
          {...props}
          type="ECPDevicesDetailsConfiguration"
          component="@jarvis/react-ecp-device-details-configuration"
        />
      ) : (
        <Route path="(|./configuration)">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsConfiguration"
            component="@jarvis/react-ecp-device-details-configuration"
          />
        </Route>
      ),
    });
  }

  if (isPartnerLinkSupported && featureFlags.enablePartnerlink && !isWex) {
    tabs.push({
      id: 3,
      label: (
        <StyledNavLink
          isWex={isWex}
          to={`${getPathname()}/${deviceId}/partnerlink`}
          activeClassName="selected"
          data-testid="partnerlink-tab"
        >
          Partner Link
        </StyledNavLink>
      ),
      title: 'Device Details: Partnerlink',
      description: '',
      content: (
        <Route path="(|/partnerlink)">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsPartnerlink"
            component="@jarvis/react-ecp-device-details-partnerlink"
          />
        </Route>
      ),
    });
  }

  if (showPolicy && !isFromUnassigned && policyReadRnPm && policyListRnPm && !isWex) {
    tabs.push({
      id: 4,
      label: (
        <StyledNavLink
          isWex={isWex}
          to={`${getPathname()}/${deviceId}/policy`}
          activeClassName="selected"
          data-testid="policy-tab"
        >
          {t('label.device_specific_policy')}
        </StyledNavLink>
      ),
      title: 'Device Details: Policy',
      description: '',
      content: (
        <Route path="(|/policy)">
          <TabMfe
            {...props}
            type="ECPDevicesDetailsPolicy"
            component="@jarvis/react-ecp-policies"
          />
        </Route>
      ),
    });
  }

  if (isWex) {
    tabs.push({
      id: 5,
      label: (
        <StyledNavLink
          isWex={isWex}
          to={`${getPathname()}/${deviceId}/fleetmanager`}
          activeClassName="selected"
          data-testid={'fleetmanager-tab'}
        >
          HP Secure Fleet Manager
        </StyledNavLink>
      ),
      title: 'Device Details: HP Secure Fleet Manager',
      description: '',
      content: (
        <TabMfe
          type="ECPDevicesDetailsFleetManager"
          component="@jarvis/react-ecp-endpointsecurity-devicespage"
        />
      ),
    });
  }

  const memoRenderComponent = useMemo(() => {
    console.log('[details] render <DeviceDetails />');

    return (
      <Wrapper className="devicesMain" data-testid="device-details-tab">
        <Router basename={baseName}>
          <Switch>
            {tabs.length !== 0 && (
              <Tabs
                controlId="extended"
                mode="extended"
                onChangeTab={
                  isWex
                    ? (id) => {
                        setCurrentTab(id);
                      }
                    : undefined
                }
                selectedTabId={currentTab}
                tabs={tabs}
                data-testid="devices-tabs"
                className={'details-tabs-list'}
              />
            )}
          </Switch>
        </Router>
      </Wrapper>
    );
  }, [shell, stack, props, currentTab]);

  return <>{memoRenderComponent}</>;
};

DeviceDetailsTab.defaultProps = {
  shell: {},
  stack: null,
};

DeviceDetailsTab.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
};

const Wrapper = styled.div`
  .details-tabs-list {
    span {
      display: flex;
      justify-content: center;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  color: black;
  text-decoration: none;

  &.selected {
    color: ${(props) => (props.isWex ? tokens.color.darkNavy7 : 'var(--hpBlue600);')};
  }
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    color: ${(props) => (props.isWex ? tokens.color.darkNavy7 : 'var(--hpBlue600);')};
  }
`;
