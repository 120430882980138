import styled, { css } from 'styled-components';
import React from 'react';

type TMediaRollIconProps = {
  index: number;
};

const MediaRollIcon = (props: TMediaRollIconProps) => {
  return (
    <RollContainer data-testid={'media-roll-icon'}>
      <OuterRoll>
        <InnerRoll>
          <span>{props.index}</span>
        </InnerRoll>
      </OuterRoll>
      <RollTail />
    </RollContainer>
  );
};

export default MediaRollIcon;

const CircleStyle = css`
  border-radius: 50%;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RollContainer = styled.div`
  position: relative;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterRoll = styled.div`
  height: 34px;
  width: 34px;
  ${CircleStyle}
`;

const InnerRoll = styled.div`
  height: 15px;
  width: 15px;
  ${CircleStyle}

  span {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
  }
`;

const RollTail = styled.div`
  position: absolute;
  width: 17px;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid #212121;
`;
