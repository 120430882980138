import styled from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { Button, IconWarningAlt } from '@veneer/core';

type TEmptyContainerProps = {
  hasRetry?: boolean;
  retryAction?: () => void;
  message?: string;
};
const EmptyContainer = (props: TEmptyContainerProps) => {
  return (
    <NoItemsArea data-testid={'empty-container-no-items'}>
      <IconWrapper>
        <IconWarningAlt size={24} />
      </IconWrapper>

      <div>{props.message}</div>

      {props.hasRetry && (
        <RetryWrapper>
          <Button appearance="ghost" onClick={props.retryAction}>
            {t('label.retry')}
          </Button>
        </RetryWrapper>
      )}
    </NoItemsArea>
  );
};

export default EmptyContainer;

EmptyContainer.defaultProps = {
  hasRetry: false,
  message: t('message.error'),
};

const NoItemsArea = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: rgba(33, 33, 33, 0.1);
  margin-bottom: 3px;
`;

const RetryWrapper = styled.div`
  margin-top: 10px;
`;
