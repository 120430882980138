import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { refinedUseGetDeviceInfo } from './deviceCacheRefinery';
import { isMockUpMode } from 'src/utils/commonMethods';

import getDeviceInfoMock from 'src/mock/deviceCache/get-device-info.json';

export const deviceCacheAPI: any = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.deviceCache);

  const getDeviceInfo = async (deviceId) => {
    let response, error;

    if (isMockUpMode()) {
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      response = refinedUseGetDeviceInfo(getDeviceInfoMock);

      // await getMockUpData('deviceCache/get-device-info').then((data) => {
      //   response = refinedUseGetDeviceInfo(data);
      // });
      return { response, error };
    }
    await client
      .show(deviceId)
      .then((res) => {
        response = refinedUseGetDeviceInfo(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getDeviceInfo,
  };
};
