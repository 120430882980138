import { store } from 'src/utils/constants';

const initialState = {
  startRootComponent: false,
  deviceId: '',
  deviceModelNumber: '',
  deviceSerialNumber: '',
  deviceGroup: null,
  isPartnerLinkSupported: false,
  partnerLinkList: [],
  entitlements: [],
  isFromUnassigned: false,
  isMultipleTab: false,
  consumables: null,
  deviceInfo: {},
  dmsList: [],
};

const detailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.details.START_ROOT_COMPONENT:
      return {
        ...state,
        startRootComponent: action.payload,
      };
    case store.details.DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload,
      };
    case store.details.DEVICE_MODEL_NUMBER:
      return {
        ...state,
        deviceModelNumber: action.payload,
      };
    case store.details.DEVICE_SERIAL_NUMBER:
      return {
        ...state,
        deviceSerialNumber: action.payload,
      };
    case store.details.IS_PARTNER_LINK_SUPPORTED:
      return {
        ...state,
        isPartnerLinkSupported: action.payload,
      };
    case store.details.IS_FROM_UNASSIGNED:
      return {
        ...state,
        isFromUnassigned: action.payload,
      };
    case store.details.IS_MULTIPLE_TAB:
      return {
        ...state,
        isMultipleTab: action.payload,
      };
    case store.details.PARTNER_LINK_LIST:
      return {
        ...state,
        partnerLinkList: action.payload,
      };
    case store.details.ENTITLEMENTS:
      return {
        ...state,
        entitlements: action.payload,
      };
    case store.details.DEVICE_GROUP:
      return {
        ...state,
        deviceGroup: action.payload,
      };
    case store.details.CONSUMABLES:
      return {
        ...state,
        consumables: action.payload,
      };
    case store.details.DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.payload,
      };
    case store.details.DMS_LIST:
      return {
        ...state,
        dmsList: action.payload,
      };
    default:
      return state;
  }
};

export default detailsReducer;
