import styled from 'styled-components';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Tabs } from '@veneer/core';
import { useStoreState } from 'src/store/useStoreState';
import { t, isEmptyData } from 'src/utils/commonMethods';
import { Cartridge, PrintHead, Media, OtherSupplies } from 'src/components/molecule';

type TSuppliesLFProps = {
  createAccordionItems: () => void;
  supplies: any;
};

const SuppliesLF = (props: TSuppliesLFProps) => {
  const { detailsRnPmState } = useStoreState();
  const { suppliesReadRnPm, devicesMediaReadRnPm } = detailsRnPmState;
  const [currentTab, setCurrentTab] = useState<string>('tab__cartridges');
  const [tabs, setTabs] = useState([]);

  useLayoutEffect(() => {
    createTabs();
  }, []);

  useEffect(() => {
    props.createAccordionItems();
  }, [currentTab]);

  const handleTabChange = (tabId) => {
    setCurrentTab(tabId);
  };

  const createTabs = () => {
    const initialTab = [];
    if (suppliesReadRnPm && !isEmptyData(props.supplies.ink)) {
      initialTab.push({
        id: 'tab__cartridges',
        label: t('label.cartridges'),
        content: <Cartridge data={props.supplies.ink} minHeight={240} />,
      });
    }
    if (suppliesReadRnPm && !isEmptyData(props.supplies.printHead)) {
      initialTab.push({
        id: 'tab__printHead',
        label: t('label.printHead'),
        content: <PrintHead data={props.supplies.printHead} />,
      });
    }
    if (devicesMediaReadRnPm && !isEmptyData(props.supplies.media)) {
      initialTab.push({
        id: 'tab__media',
        label: t('label.media'),
        content: <Media data={props.supplies.media} />,
      });
    }
    if (suppliesReadRnPm && !isEmptyData(props.supplies.otherSupplies)) {
      initialTab.push({
        id: 'tab__otherSupplies',
        label: t('label.other_supplies'),
        content: <OtherSupplies data={props.supplies.otherSupplies} />,
      });
    }
    if (!suppliesReadRnPm) setCurrentTab('none');
    setTabs(initialTab);
  };

  return (
    <>
      {(suppliesReadRnPm || devicesMediaReadRnPm) && (
        <>
          <BorderSeparator />
          <TabWrapper data-testid="supplies-tab-for-large-format">
            <Tabs
              data-testid="inner-supplies-tab"
              controlId="extended"
              mode="extended"
              selectedTabId={currentTab}
              onChangeTab={handleTabChange}
              tabs={tabs}
            />
          </TabWrapper>
        </>
      )}
    </>
  );
};

export default SuppliesLF;

const TabWrapper = styled.div`
  width: 100%;

  #tab__cartridges {
    min-width: 134px;
  }
  #tab__printHead {
    min-width: 110px;
  }
  #tab__media {
    min-width: 104px;
    span {
      min-width: unset;
    }
  }
  #tab__otherSupplies {
    min-width: 138px;
  }
`;

const BorderSeparator = styled.div`
  display: inline-block;
  border-left: 2px solid rgba(33, 33, 33, 0.1);
  margin-right: 32px;
`;
