import styled from 'styled-components';
import React from 'react';
import { MediaRollIcon } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { isEmptyData, toTitleCase } from 'src/utils/commonMethods';
import { IconCheckmarkCircle, IconWarningAlt, IconXCircle } from '@veneer/core';

type TMediaProps = {
  data: any;
};

export const Media = (props: TMediaProps) => {
  const { ecpDeviceV55 } = useShellRootContext();

  const getIcon = (type) => {
    switch (type) {
      case 'error':
        return <IconXCircle color="red6" filled={true} />;
      case 'normal':
        return <IconCheckmarkCircle color="hunterGreen6" filled={true} />;
      case 'warning':
        return <IconWarningAlt color="darkOrange5" filled={true} />;
      default:
        return <></>;
    }
  };

  return (
    <Wrapper data-testid="media">
      {!isEmptyData(props.data) &&
        props.data.map((media, idx) => (
          <MediaItem key={`media-${idx}`} data-testid={`media-item-${idx}`}>
            {media.type == 'continuousRoll' && <MediaRollIcon index={idx + 1} />}
            <DetailWrapper>
              <Text>{toTitleCase(media.id)} : </Text>
              <IconWrapper>{getIcon(media.state)}</IconWrapper>

              {ecpDeviceV55 && <Text>{toTitleCase(media.stateReason)}</Text>}
            </DetailWrapper>
          </MediaItem>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  padding: 0 40px;
`;

const MediaItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

const IconWrapper = styled.div`
  margin: 0 5px;
  display: flex;
  align-items: center;
`;

const DetailWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  display: flex;
  font-family: var(--fontFamilyBase);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;
