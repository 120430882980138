import styled, { css } from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';

type TDeviceAssessmentProps = {
  solutionMetadata: any;
};

const DeviceAssessment = (props: TDeviceAssessmentProps) => {
  const getResult = () => {
    if (props.solutionMetadata.length > 0) {
      return props.solutionMetadata[0].result;
    } else {
      return '--';
    }
  };

  return (
    <Wrapper data-testid="device-assessment">
      <BorderSeparator />

      <Content>
        <Title data-testid={'assessment-text'}>
          <Text>{t('info.assessment')}</Text>
        </Title>

        <Value>
          <CustomIcon data-testid={'device-assessment-icon'} result={getResult()} />
          <AssessmentDetail>{t(`info.${getResult()}`)}</AssessmentDetail>
        </Value>
      </Content>
    </Wrapper>
  );
};

export default DeviceAssessment;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 10px 0;
  min-width: 90px;
`;

const Content = styled.div``;

const BorderSeparator = styled.div`
  display: inline-block;
  border-left: 2px solid rgba(33, 33, 33, 0.1);
  height: 32px;
  margin-right: 10px;
`;

const Title = styled.div`
  line-height: 16px;
  font-size: 14px;
  color: var(--gray500);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Value = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
`;

const Text = styled.span`
  margin-right: 4px;
`;

const IconBackground = css`
  ${({ result }) => {
    switch (result) {
      case 'highRisk':
        return '#D41E4F';
      case 'mediumRisk':
        return '#F35D3E';
      case 'lowRisk':
        return '#FFCE33';
      case 'passed':
        return '#57AEA4';
      case 'notAssessed':
        return '#7300A9';
      case 'unknown':
        return '#000000';
    }
  }}
`;

const CustomIcon = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  display: inline-block;
  border-radius: 50%;
  background-color: ${IconBackground};
`;

const AssessmentDetail = styled.div`
  line-height: 24px;
`;
