import React, { useCallback, useEffect } from 'react';
import MfeLoader from './MfeLoader';
import { useDispatch } from 'react-redux';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { refreshRootComponent } from 'src/utils/commonMethods';

type TGlobalHeaderProps = {
  title?: string;
};

const GlobalHeader = (props: TGlobalHeaderProps) => {
  const dispatch = useDispatch();
  const { events, localization } = useShellRootContext();

  const handleReloadPage = () => {
    refreshRootComponent(dispatch);
  };

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', handleReloadPage);
    return () => {
      events.removeEventListener('ecp-banner-reload-call', handleReloadPage);
    };
  }, [events, handleReloadPage]);

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <MfeLoader
          disableContainerPadding
          {...{
            component,
            type,
            localization,
            title: props.title ?? '',
          }}
        />
      );
    },
    [props.title],
  );

  const renderGlobalHeader = () => {
    return (
      <div className="react-ecp-header-wrapper">
        <SubMfe type="headerTitle" component="@jarvis/react-ecp-header" />
      </div>
    );
  };

  return renderGlobalHeader();
};

export default GlobalHeader;
