import { createContext } from 'react';

const ecpDeviceV55 = null;
const stack = null;
const shell = null;
const localization = null;
const useToast = null;
const events = null;
const authProvider = null;
const navigation = null;
const getBreadcrumb = null;
const setBreadcrumb = null;
const showConfiguration = null;
const showOverview = null;
const showFWUpdate = null;
const showPolicy = null;
const showJobs = null;
const showLastUpdatedSection = null;
const showDetailsTab = null;
const customRelativePath = null;
const featureFlags = null;
const accessControl = null;
const theme = null;
const isWex = null;
const mode = null;
const getConfig = null;
const isFromApps = null;
const appUuid = null;

const shellRootContext = createContext({
  ecpDeviceV55,
  stack,
  shell,
  localization,
  useToast,
  events,
  authProvider,
  navigation,
  getBreadcrumb,
  setBreadcrumb,
  showConfiguration,
  showOverview,
  showFWUpdate,
  showPolicy,
  showJobs,
  showLastUpdatedSection,
  showDetailsTab,
  customRelativePath,
  featureFlags,
  accessControl,
  theme,
  isWex,
  mode,
  getConfig,
  isFromApps,
  appUuid,
});

export default shellRootContext;
