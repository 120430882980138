import { store } from 'src/utils/constants';

export const storeStartRootComponent = (_payload) => {
  return {
    type: store.details.START_ROOT_COMPONENT,
    payload: _payload,
  };
};

export const storeDeviceId = (_payload) => {
  return {
    type: store.details.DEVICE_ID,
    payload: _payload,
  };
};

export const storeDeviceModelNumber = (_payload) => {
  return {
    type: store.details.DEVICE_MODEL_NUMBER,
    payload: _payload,
  };
};

export const storeDeviceSerialNumber = (_payload) => {
  return {
    type: store.details.DEVICE_SERIAL_NUMBER,
    payload: _payload,
  };
};

export const storeDeviceGroup = (_payload) => {
  return {
    type: store.details.DEVICE_GROUP,
    payload: _payload,
  };
};

export const storeIsPartnerLinkSupported = (_payload) => {
  return {
    type: store.details.IS_PARTNER_LINK_SUPPORTED,
    payload: _payload,
  };
};

export const storeIsFromUnassigned = (_payload) => {
  return {
    type: store.details.IS_FROM_UNASSIGNED,
    payload: _payload,
  };
};

export const storeIsMultipleTab = (_payload) => {
  return {
    type: store.details.IS_MULTIPLE_TAB,
    payload: _payload,
  };
};

export const storePartnerLinkList = (_payload) => {
  return {
    type: store.details.PARTNER_LINK_LIST,
    payload: _payload,
  };
};

export const storeEntitlements = (_payload) => {
  return {
    type: store.details.ENTITLEMENTS,
    payload: _payload,
  };
};

export const storeConsumables = (_payload) => {
  return {
    type: store.details.CONSUMABLES,
    payload: _payload,
  };
};

export const storeDeviceInfo = (_payload) => {
  return {
    type: store.details.DEVICE_INFO,
    payload: _payload,
  };
};

export const storeDmsList = (_payload) => {
  return {
    type: store.details.DMS_LIST,
    payload: _payload,
  };
};
