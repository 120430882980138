import { permission } from 'src/utils/constants';
import { getPermissionSet, isMockUpMode } from 'src/utils/commonMethods';
import { storePermission } from './actions';

import getJShellScopesMock from 'src/mock/get-jshell-scopes.json';

export const getDetailsRnPmActions = async (accessControl, dispatch) => {
  if (isMockUpMode()) {
    localStorage.setItem('jshellUserScopes-mockup', JSON.stringify(getJShellScopesMock.scopes));
  }
  return dispatchAll(accessControl, dispatch);
};

const dispatchAll = (accessControl, dispatch) => {
  const permissionSet = [];
  getPermissionSet(permission, permissionSet);

  permissionSet.forEach((scope) => {
    accessControl.checkScopes([{ scope: scope }]).then((res) => {
      dispatch(storePermission(res, scope));
    });
  });
};
