import { useSelector, RootStateOrAny } from 'react-redux';

export const useStoreState: any = () => {
  const detailsState = useSelector((state: RootStateOrAny) => state.detailsReducer);
  const detailsRnPmState = useSelector((state: RootStateOrAny) => state.detailsRnPmReducer);

  return {
    detailsState,
    detailsRnPmState,
  };
};
