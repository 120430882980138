import moment from 'moment';
import AssetsProviderFactory from 'src/assets/AssetsProviderFactory';
import { dateFormat } from './constants';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { storeStartRootComponent } from 'src/store/details/action';

type ManageMfeProps = {
  country?: string;
  language?: string;
  stack?: number;
  authProvider: JarvisAuthProvider;
  isWex?: boolean;
};

const localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
  isWex: false,
};

export const setProps = () => {
  const { authProvider, localization, stack, isWex } = useShellRootContext();
  localProps.authProvider = authProvider;
  localProps.language = localization.language;
  localProps.stack = stack ?? 1;
  localProps.country = localization.country;
  localProps.isWex = isWex;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(
    props.language || 'en',
    props.country || 'US',
  );
  return assetsProvider.getText(`ecp-device-details.${subkey}`, args);
};

export const fetchImage = (images) => {
  if (images[3]) {
    return images[3].url;
  } else if (images[2]) {
    return images[2].url;
  } else if (images[1]) {
    return images[1].url;
  } else if (images[0]) {
    return images[0].url;
  }
};

export const getCurrentDateTime = (format) => {
  return moment().format(format);
};

export const getFormattedDate = (format, date) => {
  if (date === undefined) return '--';
  moment.locale('en');
  return moment(date).format(format);
};

export const capitalizeFirstLetter = (str) => {
  return str.replace(/^./, str[0].toUpperCase());
};

export const refreshRootComponent = (dispatch) => {
  dispatch(storeStartRootComponent(false));

  setTimeout(() => {
    dispatch(storeStartRootComponent(true));
  }, 100);
};

export const isEmptyData = (data) => {
  if (data === null || data === undefined) {
    return true;
  }
  return data.length === 0;
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const refined = (data: any) => {
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    return data;
  }
};

export const refinedDate = (data: any) => {
  moment.locale('en');
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    if (moment(data).isValid()) return moment(data).format(dateFormat.default);
    else return '--';
  }
};

export const getMockUpData = async (moduleName: string) => {
  return await import(`src/mock/${moduleName}.json`);
};

export const isMockUpMode = () => {
  const props = getProps();

  if (props.isWex) {
    return true;
  } else {
    return !!(
      (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') &&
      localStorage.getItem('enable-devices-mockup-data')
    );
  }
};

export const getPermissionSet = (permission: any, permissionSet: any[]) => {
  for (const key in permission) {
    if (permission[key] instanceof Object) {
      getPermissionSet(permission[key], permissionSet);
    } else {
      permissionSet.push(permission[key]);
    }
  }
  return permissionSet;
};

export const getPathNameForWex = () => {
  if (window.location.pathname.startsWith('/ui/view')) return '/ui/view/devicesPrinter';
  return '/view/devicesPrinter';
};

export const getBaseName = () => {
  const { customRelativePath, navigation, isWex, isFromApps } = useShellRootContext();
  const baseurl = customRelativePath || '/';

  if (isWex) {
    return '';
  } else {
    if (navigation) {
      return navigation.createHref({ pathname: `${baseurl}${isFromApps ? '/devices' : ''}` });
    } else {
      return `${baseurl}${isFromApps ? '/devices' : ''}`;
    }
  }
};

export const getPathname = () => {
  const { isWex } = useShellRootContext();

  if (isWex) {
    if (window.location.pathname.startsWith('/ui/view')) {
      return '/ui/view/devicesPrinter';
    }
    return '/view/devicesPrinter';
  } else {
    return '';
  }
};

export const getStatus = (status: {
  acceptingJobs: boolean;
  printerStateSeverity: string;
  printerState: string;
}) => {
  if (!status || status === null || status === undefined) {
    return 'ERROR';
  }
  const acceptingJobs = status?.acceptingJobs;
  const printerStateSeverity = status?.printerStateSeverity;
  const printerState = status?.printerState;

  if (
    (acceptingJobs === false && printerState !== 'PROCESSING') ||
    printerStateSeverity === 'ERROR'
  ) {
    return 'ERROR';
  } else if (printerStateSeverity === 'WARNING') {
    return 'WARNING';
  } else {
    return 'READY';
  }
};
