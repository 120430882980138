import { store } from 'src/utils/constants';

const initialState = {
  featuresConstraintsReadRnPm: true,
  suppliesReadRnPm: true,
  devicesMediaReadRnPm: true,
  policyReadRnPm: true,
  policyListRnPm: true,
  jobHistoryReadRnPm: true,
};

const detailsRnPmReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.detailsRnPm.STORE_PERMISSION_FEATURES_CONSTRAINTS_READ:
      return {
        ...state,
        featuresConstraintsReadRnPm: action.payload,
      };
    case store.detailsRnPm.STORE_PERMISSION_SUPPLIES_READ:
      return {
        ...state,
        suppliesReadRnPm: action.payload,
      };

    case store.detailsRnPm.STORE_PERMISSION_DEVICES_MEDIA_READ:
      return {
        ...state,
        devicesMediaReadRnPm: action.payload,
      };

    case store.detailsRnPm.STORE_PERMISSION_POLICY_READ:
      return {
        ...state,
        policyReadRnPm: action.payload,
      };
    case store.detailsRnPm.STORE_PERMISSION_POLICY_LIST:
      return {
        ...state,
        policyListRnPm: action.payload,
      };
    case store.detailsRnPm.STORE_PERMISSION_JOB_HISTORY_READ:
      return {
        ...state,
        jobHistoryReadRnPm: action.payload,
      };

    default:
      return state;
  }
};

export default detailsRnPmReducer;
