import styled from 'styled-components';
import React from 'react';
import { useStoreState } from 'src/store/useStoreState';
import { SuppliesLF, SuppliesNonLF } from 'src/components/organism';

type TSuppliesProps = {
  createAccordionItems: () => void;
};

const Supplies = (props: TSuppliesProps) => {
  const { detailsState } = useStoreState();
  const { consumables, isMultipleTab } = detailsState;
  const validInk = consumables.ink?.length !== 0 ? 1 : 0;
  const validOtherSupplies = consumables.otherSupplies?.length !== 0 ? 1 : 0;
  const validPrintHead = consumables.printHead?.length !== 0 ? 1 : 0;
  const validMedia = consumables.media === undefined ? 1 : 0;

  const getSuppliesView = () => {
    const checkValidCount = validInk + validOtherSupplies + validPrintHead + validMedia >= 2;

    if (isMultipleTab && checkValidCount) {
      console.log('[details] render multiple tabs <SuppliesLF />');
      return (
        <Wrapper>
          <SuppliesLF createAccordionItems={props.createAccordionItems} supplies={consumables} />
        </Wrapper>
      );
    }

    console.log('[details] render no tab <SuppliesNonLF />');
    return (
      validInk === 1 && (
        <Wrapper>
          <SuppliesNonLF supplies={consumables} />
        </Wrapper>
      )
    );
  };

  return <>{getSuppliesView()}</>;
};

export default Supplies;

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;

  @media (max-width: 1111px) {
    width: 100%;
    margin-left: -8px;
  }
`;
