import styled, { css } from 'styled-components';
import React from 'react';
import tokens from '@veneer/tokens';
import { Button } from '@veneer/core';
import { DmsModal } from 'src/components/organism';
import { t, getStatus } from 'src/utils/commonMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TDeviceStatusMessageProps = {
  status: {
    acceptingJobs: boolean;
    printerStateSeverity: string;
    printerState: string;
  };
};

export const DeviceStatusMessage = (props: TDeviceStatusMessageProps) => {
  const { isWex, ecpDeviceV55 } = useShellRootContext();
  const [openDmsModal, setOpenDmsModal] = React.useState(false);
  const status = getStatus(props.status);

  const mockMultipleDMS = [
    'Incompatible Cyan Cartridge Long Long Messages',
    'Incompatible Cyan Cartridge',
    'Incompatible Cyan Cartridge',
    'Incompatible Cyan Cartridge',
  ];

  const getDeviceStatusMessage = () => {
    const messages = mockMultipleDMS;

    return (
      <>
        <Text color={'blue'} isWex={isWex}>
          {messages[0]}
        </Text>

        <Text>&</Text>

        <Text color={'blue'} isWex={isWex}>
          {messages.length - 1} more
        </Text>
      </>
    );
  };

  const handleDmsModal = () => {
    setOpenDmsModal((prev) => !prev);
  };

  const getStatusMessage = (status: string) => {
    switch (status) {
      case 'ERROR':
        return t('info.unableToPrint');
      case 'WARNING':
        return t('info.needsAttention');
      case 'READY':
        return t('info.readyToPrint');
    }
  };

  return (
    <Wrapper>
      <Title>{t('info.deviceStatusMessage')}</Title>

      <Content>
        {ecpDeviceV55 ? (
          <>
            <Button
              data-testid={'device-info-status-msg-button'}
              appearance="ghost"
              onClick={handleDmsModal}
            >
              {getDeviceStatusMessage()}
            </Button>
            <DmsModal
              data-testid={'device-info-dmsmodal'}
              open={openDmsModal}
              onClose={setOpenDmsModal}
            />
          </>
        ) : (
          <Value>{getStatusMessage(status)}</Value>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: 'Forma DJR UI';
  margin-top: 10px;
  margin-left: 12px;
  margin-bottom: 25px;
  flex-wrap: wrap;
`;

const Title = styled.div`
  font-size: 14px;
  color: var(--gray500);
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Text = styled.span`
  color: var(--gray500);
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 0 3px;
  max-width: 356px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ color }) => {
    if (color === 'blue') {
      return css`
        color: ${(props) => (props.isWex ? tokens.color.darkNavy7 : '#0076ad')};
      `;
    }
    return css`
      color: #212121;
    `;
  }}
`;

const Value = styled.div`
  color: #333333;
  font-weight: bold;
  font-size: 16px;
`;
