import styled, { css } from 'styled-components';
import tokens from '@veneer/tokens';
import React from 'react';
import { t } from 'src/utils/commonMethods';

type TDeviceConnectivityProps = {
  connectionState: string;
};

const DeviceConnectivity = (props: TDeviceConnectivityProps) => {
  return (
    <Wrapper data-testid={'device-connectivity'}>
      <BorderSeparator />

      <Content>
        <Title data-testid={'connectivity-text'}>
          <Text>{t('info.connectivity')}</Text>
        </Title>

        <Value>
          <CustomIcon
            data-testid={'device-connectivity-icon'}
            connectionState={props.connectionState}
          />
          <ConnectivityDetail>{t(`info.${props.connectionState}`)}</ConnectivityDetail>
        </Value>
      </Content>
    </Wrapper>
  );
};

export default DeviceConnectivity;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 10px 0;
  min-width: 90px;
`;

const BorderSeparator = styled.div`
  display: inline-block;
  border-left: 2px solid rgba(33, 33, 33, 0.1);
  height: 32px;
  margin-right: 10px;
`;

const Content = styled.div``;

const Title = styled.div`
  line-height: 16px;
  font-size: 14px;
  color: var(--gray500);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Value = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
`;

const Text = styled.span`
  margin-right: 4px;
`;

const IconBackground = css`
  ${({ connectionState }) => {
    switch (connectionState) {
      case 'online':
        return tokens.color.green6;
      case 'offline':
        return tokens.color.gray4;
    }
  }}
`;

const CustomIcon = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  display: inline-block;
  border-radius: 50%;
  background-color: ${IconBackground};
`;

const ConnectivityDetail = styled.div`
  line-height: 24px;
`;
