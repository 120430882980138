import { isMockUpMode } from 'src/utils/commonMethods';
import {
  storeDeviceId,
  storeIsFromUnassigned,
  storeStartRootComponent,
} from 'src/store/details/action';

export const getDetailsStoreActions = (props) => {
  if (isMockUpMode()) {
    return [storeStartRootComponent(true), storeDeviceId(props.deviceID ?? 'demoId')];
  }
  return [
    storeStartRootComponent(true),
    storeDeviceId(props.deviceID || props.history.location.pathname.split('/')[2]),
    storeIsFromUnassigned(props.isFromUnassigned ?? false),
  ];
};
