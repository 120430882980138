import { permission, store } from 'src/utils/constants';

export const storePermission = (_payload, scope) => {
  switch (scope) {
    case permission.features.constraint.READ:
      return {
        type: store.detailsRnPm.STORE_PERMISSION_FEATURES_CONSTRAINTS_READ,
        payload: _payload,
      };
    case permission.supplies.READ:
      return {
        type: store.detailsRnPm.STORE_PERMISSION_SUPPLIES_READ,
        payload: _payload,
      };
    case permission.devices.media.READ:
      return {
        type: store.detailsRnPm.STORE_PERMISSION_DEVICES_MEDIA_READ,
        payload: _payload,
      };
    case permission.policy.READ:
      return {
        type: store.detailsRnPm.STORE_PERMISSION_POLICY_READ,
        payload: _payload,
      };
    case permission.policy.LIST:
      return {
        type: store.detailsRnPm.STORE_PERMISSION_POLICY_LIST,
        payload: _payload,
      };
    case permission.job.history.READ:
      return {
        type: store.detailsRnPm.STORE_PERMISSION_JOB_HISTORY_READ,
        payload: _payload,
      };
  }
};
