import styled from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { IconInfo, IconMinusCircle, IconWarningAlt, Tooltip } from '@veneer/core';

type StatusType = 'error' | 'warning' | 'inform';

type StatusTooltipProps = {
  status: StatusType;
  stateReason: string;
};

const StatusTooltip = (props: StatusTooltipProps) => {
  const { ecpDeviceV55 } = useShellRootContext();

  const getIconByType = () => {
    switch (props.status) {
      case 'error':
        return (
          <IconMinusCircle data-testid={'icon-minus-circle-test'} color="red6" filled={true} />
        );
      case 'warning':
        return (
          <IconWarningAlt data-testid={'icon-warning-alt-test'} color="darkOrange5" filled={true} />
        );
      case 'inform':
        return <IconInfo data-testid={'icon-info-test'} color="hpBlue6" filled={true} />;
    }
  };

  if (props.status !== 'error' && props.status !== 'inform' && props.status !== 'warning') {
    return <></>;
  }

  return (
    <Wrapper>
      {ecpDeviceV55 ? (
        <Tooltip
          data-testid={'status-tooltip'}
          placement="leading"
          contentHideDelayOnHover={0}
          content={
            <TooltipContent>
              <IconWrapper>{getIconByType()}</IconWrapper>

              <ErrorMessage>{t(props.stateReason)}</ErrorMessage>
            </TooltipContent>
          }
        >
          {getIconByType()}
        </Tooltip>
      ) : (
        <>{getIconByType()}</>
      )}
    </Wrapper>
  );
};

export default StatusTooltip;

const Wrapper = styled.div`
  margin-top: 30px;
  height: 30px;
`;

const TooltipContent = styled.span`
  display: inline-flex;
  background: white !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  border: none !important;
  border-radius: 5px;
  min-width: 220px;
  padding: 7px;
  margin: -10px;
`;

const IconWrapper = styled.span`
  margin-left: -3px;
`;

const ErrorMessage = styled.span`
  font-family: var(--fontFamilyBase);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: #737373;
  margin-left: 3px;
  margin-top: 5px;
`;
