import styled from '@emotion/styled';
import React, { useEffect, useMemo } from 'react';
import { css } from 'styled-components';
import { CommonLoading } from 'src/components/atom';
import { useStoreState } from 'src/store/useStoreState';
import { accordionHelper } from 'src/veneerHelper';
import { Accordion, Button, IconMinusCircle, Modal } from '@veneer/core';

type TDmsModalProps = {
  open: boolean;
  onClose: (boolean) => void;
};

const DmsModal = (props: TDmsModalProps) => {
  const { detailsState } = useStoreState();
  const { dmsList } = detailsState;
  const { accordionState, accordionSetState, accordionFunc } = accordionHelper();

  const handleClose = () => {
    props.onClose(false);
  };

  useEffect(() => {
    accordionSetState.setItems(createAccordionItems());
  }, [dmsList]);

  const createAccordionItems = () => {
    return dmsList.map((item, idx) => ({
      id: idx.toString(),
      expanded: idx === 0,
      header: {
        id: idx.toString(),
        centralArea: <div></div>,
        startArea: (
          <>
            <IconMinusCircle color="red6" filled={true} />
            <ItemTitle>Error Message title</ItemTitle>
          </>
        ),
      },
      content: <div>{item}</div>,
    }));
  };

  const memoContent = useMemo(() => {
    if (dmsList.length > 0) {
      return (
        <>
          <Header>
            <Title>Device Status Message</Title>
            <Button
              data-testid="dms-close-button"
              customStyle={CloseButton}
              appearance={'ghost'}
              onClick={handleClose}
            >
              X
            </Button>
          </Header>
          <Accordion
            border="outlined"
            items={accordionState.items}
            onCollapse={accordionFunc.handleCollapse}
            onExpand={accordionFunc.handleExpand}
          />
        </>
      );
    } else {
      return <CommonLoading />;
    }
  }, [dmsList, accordionState.items]);

  return (
    <Modal
      align="center"
      id="device-status-message-modal"
      data-testid="device-status-message-modal"
      maxWidth={1100}
      expanded={true}
      show={props.open}
      onClose={handleClose}
      className={'jarvis__react-shell-common-layout'} // Note: must add clasName to Modal
    >
      {memoContent}
    </Modal>
  );
};

export default DmsModal;

const Title = styled.div`
  width: 1020px;
  height: 60px;
  padding: 20px 0 0 20px;
  gap: 0;

  color: #212121;
  font-family: var(--fontFamilyBase);
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
`;

const ItemTitle = styled.span`
  margin-left: 2px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
`;

const CloseButton = css`
  color: gray !important;
  min-width: unset !important;
`;
