import { refined } from 'src/utils/commonMethods';

export const refinedUseGetCollectionByEntityId = (data) => {
  return {
    contents:
      data.contents?.length > 0
        ? data.contents?.map((obj) => ({
            name: refined(obj.name),
          }))
        : [{ name: refined(undefined) }],
  };
};
