import styled from 'styled-components';
import React from 'react';
import { Cartridge } from 'src/components/molecule';
import { useStoreState } from 'src/store/useStoreState';

type TSuppliesNonLFProps = {
  supplies: any;
};

const SuppliesNonLF = (props: TSuppliesNonLFProps) => {
  const { detailsRnPmState } = useStoreState();
  const { suppliesReadRnPm } = detailsRnPmState;

  return (
    <>
      {suppliesReadRnPm && (
        <>
          {props.supplies?.ink?.length !== 0 && <BorderSeparator />}
          <TabWrapper data-testid="supplies-tab-for-common">
            <Cartridge data={props.supplies?.ink} minHeight={300} />
          </TabWrapper>
        </>
      )}
    </>
  );
};

export default SuppliesNonLF;

const TabWrapper = styled.div`
  width: 100%;
`;

const BorderSeparator = styled.div`
  display: inline-block;
  border-left: 2px solid rgba(33, 33, 33, 0.1);
  margin-right: 32px;
`;
