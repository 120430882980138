import styled from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { COLOR_CODE } from 'src/utils/constants';
import { IconMinusCircle } from '@veneer/core';

type TSuppliesWarningProps = {
  inks: any;
};

const SuppliesWarning = (props: TSuppliesWarningProps) => {
  return (
    <Wrapper data-testid={'supplies-warning-wrapper'}>
      <IconWrapper>
        <IconMinusCircle color="red6" filled={true} />
      </IconWrapper>

      <Description>
        <Title>{t('label.nonChip')}</Title>
        <Colors>
          {props.inks.map((ink) => {
            return (
              <TonerLabel key={`toner-label-${ink}`} color={ink}>
                {ink}
              </TonerLabel>
            );
          })}
        </Colors>
        <Message>
          {t('warning.nonChip')}
          <a href={'https://www.hp.com/learn/ds'}> www.hp.com/learn/ds</a>
        </Message>
      </Description>
    </Wrapper>
  );
};

export default SuppliesWarning;

const Wrapper = styled.div`
  background: #f2e1e1;
  margin: 20px 0;
  padding: 12px 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  margin-right: 12px;
`;

const Description = styled.div``;

const Title = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 1px;
`;

const Colors = styled.div``;

const TonerLabel = styled.div`
  width: 16px;
  height: 16px;

  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;

  background: ${({ color }) => COLOR_CODE[color]};
  border-radius: 8px;

  margin: 2px 4px 4px 0;
`;

const Message = styled.span`
  font-size: 90%;
`;
