import styled from 'styled-components';
import React from 'react';
import { capitalizeFirstLetter } from 'src/utils/commonMethods';

type TDeviceGroupStatusProps = {
  deviceGroup: string;
};

const DeviceGroupStatus = (props: TDeviceGroupStatusProps) => {
  return (
    <Wrapper data-testid="device-status">
      <BorderSeparator />

      <AttrWrapper>
        <AttrTitle data-testid="group-text">GROUP</AttrTitle>
        <AttrDetail>
          <StatusDetail>{capitalizeFirstLetter(props.deviceGroup)}</StatusDetail>
        </AttrDetail>
      </AttrWrapper>
    </Wrapper>
  );
};

export default DeviceGroupStatus;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 10px 0;
`;

const AttrWrapper = styled.div``;

const BorderSeparator = styled.div`
  display: inline-block;
  border-left: 2px solid rgba(33, 33, 33, 0.1);
  height: 32px;
  margin-right: 10px;
`;

const AttrTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  color: var(--gray500);
`;

const AttrDetail = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray600);
`;

const StatusDetail = styled.div`
  font-size: 16px;
  line-height: 24px;
`;
