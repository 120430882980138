import styled from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { CommonLoading } from 'src/components/atom';
import { ErrorBoundary } from 'react-error-boundary';
import { Card, IconXCircle } from '@veneer/core';

type TMfeLoaderProps = {
  component?: string;
  fullScreen?: boolean;
  setIsLoadingMfe?: (isLoading: boolean) => void;
  [key: string]: unknown;
};

const MfeLoader: React.FC<TMfeLoaderProps> = ({ component, ...props }) => {
  const Card = React.lazy(() => System.import(component));

  const ErrorFallback = () => (
    <ErrorWrapper
      appearance="dropShadow"
      content={
        <>
          <IconXCircle color="red6" filled size={48} />
          <ErrorText>{t('error_text')}</ErrorText>
        </>
      }
    />
  );

  return (
    <Container data-testid="mfe-loader">
      {component ? (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <React.Suspense fallback={<CommonLoading />}>
            <Card {...props} />
          </React.Suspense>
        </ErrorBoundary>
      ) : (
        <LoaderWrapper appearance="dropShadow" data-testid="loader-wrapper" />
      )}
    </Container>
  );
};

export default MfeLoader;

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  max-width: 100%;

  & > * {
    max-width: 100%;
  }
`;

const LoaderWrapper = styled(Card)`
  width: 100%;
  height: 100%;
`;

const ErrorWrapper = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ErrorText = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: red;
  text-align: center;
  max-width: 250px;
`;
