import React from 'react';
import MfeLoader from './MfeLoader';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const TabMfe = (props) => {
  const { detailsState } = useStoreState();
  const {
    deviceId,
    deviceInfo,
    entitlements,
    isFromUnassigned,
    deviceModelNumber,
    deviceSerialNumber,
  } = detailsState;
  const {
    isWex,
    stack,
    shell,
    appUuid,
    getConfig,
    isFromApps,
    navigation,
    localization,
    authProvider,
    accessControl,
  } = useShellRootContext();

  return (
    <div style={{ padding: '16px 0px 0px 0px' }} data-testid={'tab-mfe'}>
      <MfeLoader
        disableContainerPadding
        {...props}
        {...{
          component: props.component,
          type: props.type,
          stack,
          shell,
          localization,
          deviceId,
          deviceModelNumber,
          deviceSerialNumber,
          isFromUnassigned,
          entitlements,
          deviceInfo,
          navigation,
          accessControl,
          authProvider,
          isWex,
          getConfig,
          appUuid,
          isFromApps,
        }}
      />
    </div>
  );
};
export default TabMfe;
